import React from "react"
import Layout from "../components/layout"
import {graphql} from "gatsby";
import SEO from "../components/seo";
import DoctorPreview from "../components/doctorPreview";

class Doctors extends React.Component {
    render() {
        const {data} = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const doctors = data.allContentfulDoctor.edges;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Lekarze specjaliści Sandomierz i okolice" />
                <div className="mx-auto container">
                    <div className="py-10">
                        <div className="sm:flex sm:items-center">
                            <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                                <svg className="fill-current text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512">
                                    <path
                                        d="M315.793 19.932h-59.796v37.372h41.109v70.384c0 61.82-50.297 112.117-112.117 112.117S72.873 189.509 72.873 127.689V57.304h39.864V19.932h-58.55c-10.321 0-18.686 8.365-18.686 18.686v89.071c0 82.431 67.058 149.489 149.489 149.489s149.489-67.058 149.489-149.489V38.618c0-10.321-8.365-18.686-18.686-18.686z"/>
                                    <path
                                        d="M388.668 292.749v88.448c0 51.518-41.913 93.431-93.431 93.431s-93.431-41.913-93.431-93.431V259.114h-37.372v122.083c0 72.122 58.674 130.803 130.803 130.803 72.122 0 130.803-58.681 130.803-130.803v-88.448h-37.372z"/>
                                    <path
                                        d="M407.361 171.912c-38.132 0-69.145 31.013-69.145 69.139 0 38.12 31.013 69.139 69.139 69.139 38.126 0 69.139-31.019 69.145-69.139-.001-38.126-31.019-69.139-69.139-69.139zm-.006 100.906c-17.521 0-31.773-14.251-31.773-31.766s14.251-31.766 31.773-31.766c17.515 0 31.766 14.251 31.766 31.766s-14.251 31.766-31.766 31.766zM112.114 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686S130.8 68.871 130.8 58.55V18.686C130.8 8.365 122.435 0 112.114 0zM256.62 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686s18.686-8.365 18.686-18.686V18.686C275.306 8.365 266.941 0 256.62 0z"/>
                                </svg>
                            </div>
                            <h1 className="text-4xl leading-none font-bold text-gray-800 mt-2 sm:mt-0 sm:ml-4">Nasi specjaliści</h1>
                        </div>
                        <p className="text-gray-700 md:w-1/2 mt-3">Oferujemy szeroką gamę usług medycznych, a nasi lekarze specjaliści posiadają szereg dyplomów i cerytfikatów. Sprawdź, w czym możemy Ci pomóc</p>
                    </div>

                    <div className="flex flex-wrap mb-8">
                        {doctors.map(({ node }, index) => {
                            return (
                                <DoctorPreview doctor={node} key={index} />
                            )
                        })}
                    </div>
                </div>
            </Layout>
        )
    }
}


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDoctor(sort: {fields: createdAt, order: ASC}) {
        edges {
          node {
            avatar {
              fluid {
                srcSet
              }
            }
            profession
            name
            specializations {
              slug
              name
            }
          }
        }
    }
}`;

export default Doctors;